import React from "react"
import axios from "axios"
import InputMask from "react-input-mask"
import {
  Box,
  Row,
  Col,
  Text,
  settings,
  Absolute,
  Button,
  LoadFont,
} from "boostly-ui2"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import "../components/global.css"
import Gradient from "../components/gradient"
import SEO from "../components/seo"

const H1 = props => (
  <Box pl={4} pb={3}>
    <Text.title display="block" size={"28px"} color="white" {...props} />
  </Box>
)

const IndexPage = () => (
  <Box>
    <SEO title="Brochure" keywords={[`boostly`]} />
    <LoadFont />
    <Section theme="purple">
      <Box pl={3}>
        <Logo size={42} />
        <Box w="190px">
          <Text.title color="white" size={"22px"} lineHeight="1.3">
            exists to get your restaurant more business
          </Text.title>
        </Box>
      </Box>
      <Absolute top={-12} right={-12}>
        <Logomark size={100} />
      </Absolute>
    </Section>
    <Section theme="blue">
      <H1>What</H1>
      <Heading color={settings.colors.blue}>
        What <span style={{ color: settings.colors.midnight }}>Boostly</span>{" "}
        Does <span role="img">💪</span>
      </Heading>
      <Paragraph>
        {({ Hl }) => (
          <>
            Boostly keeps up with the latest marketing and customer engagement
            trends in order to put them to work for your restaurant through the
            magic of software <span role="img">✨</span>
          </>
        )}
      </Paragraph>
      <Heading color={settings.colors.blue}>
        What <span style={{ color: settings.colors.midnight }}>You</span> Do 💁‍♀️
      </Heading>
      <Paragraph>
        {({ Hl }) => (
          <>
            Boostly can work on autopilot so you can keep focusing on other
            important parts of your business. Feel free to just enjoy the
            reports we send showing how much money Boostly helped you make 💸
          </>
        )}
      </Paragraph>
    </Section>
    <Section theme="red">
      <H1>How</H1>
      <Heading color={settings.colors.red}>
        1. Customer Data <span role="img">👨‍🚀</span>
      </Heading>
      <Paragraph color={settings.colors.red}>
        {({ Hl, Break, Nl }) => (
          <>
            Businesses in every industry{" "}
            <Hl>thrive or die on how well available data is leveraged</Hl>. Most
            restaurants fall into two categories: (1) those who have no record
            of who their customers are and (2) those who do and do nothing with
            it 🤷‍♀️
            <Break />
            Boostly specializes in helping you{" "}
            <Hl>capture and manage customer data</Hl> from every segment of your
            restaurant from dine-in to take-out. We build you a unified customer
            database that you own; it’s your data.
            <Break />
            With customer data in hand we are empowered to get them: 
            <Nl />
            🚪 back in the door
            <Nl />
            👯‍♀️ bringing friends
            <Nl />
            📣 saying nice things online
          </>
        )}
      </Paragraph>

      <Heading color={settings.colors.red}>2. Power of Text 💥💬</Heading>
      <Paragraph color={settings.colors.red}>
        {({ Hl, Break }) => (
          <>
            Today, there is no higher form of engagment than when you{" "}
            <Hl>join your customer’s conversations</Hl> 👋
            <Break />
            With their number (and always their permission), texting enables
            Boostly to <Hl>engage your customer in real-time</Hl> to:
            <Break />
            <Showcase>
              {[
                "🗓 Send Weekly Specials",
                `Stay top of mind and rotate through a list of preset specials`,
              ]}
            </Showcase>
            <Showcase>
              {[
                "⭐️ Request Reviews & Feedback",
                `Generate 10x more 5-star reviews and learn about problems as they happen`,
              ]}
            </Showcase>
            <Showcase>
              {[
                "🎉 Invite Customers to Celebrate",
                `Get groups and raise likelihood of visiting on special days like their birthday by 215%`,
              ]}
            </Showcase>
            <Showcase>
              {[
                "👋 Remind A Customer You Exist",
                `Increase visits after 30 days without seeing a customer by 75%`,
              ]}
            </Showcase>
            <Showcase>
              {[
                "🔥 Ignite Word of Mouth",
                `Get customers to share you with friends through strategic referral programs tracked 100% by Boostly`,
              ]}
            </Showcase>
          </>
        )}
      </Paragraph>
    </Section>
    <Section theme="blue">
      <H1>Why</H1>
      <Heading color={settings.colors.red}>Measureable ROI 📈</Heading>
      <Paragraph>
        {({ Hl, Break, Nl }) => (
          <>
            In contrast to the fingers-crossed approach 🤞 to marketing spend…
            Boostly is anxious to show your return on investment through regular
            reporting.
            <Break />
            Boostly will show you
            <Nl />
            - customer database growth
            <Nl />
            - promotions redeemed
            <Nl />
            - physical check-ins
            <Nl />
            - customers referred
            <Nl />
            - reviews left
            <Nl />- feedback received
          </>
        )}
      </Paragraph>
      <Heading color={settings.colors.red}>Set And Forget</Heading>
      <Paragraph>
        {({ Hl, Break, Nl }) => (
          <>
            Did we mention Boostly can run on Autopilot?
            <Break />
            You have a lot on your plate already from staffing to putting out
            fires figurative literal <span role="img">🔥</span> Let Boostly take
            this one off your plate.
          </>
        )}
      </Paragraph>
      <Heading color={settings.colors.red}>Restaurant Friendly Costs</Heading>
      <Paragraph>
        {({ Hl, Break, Nl }) => (
          <>
            - $99 / month
            <Nl />
            - 3.5 cents / text
            <Nl />
            - $299 set-up that includes tablet kiosk
            <Break />
            We're no mathmatician but we think that beats paying salary for a
            marketing expert ¯\_(ツ)_/¯
          </>
        )}
      </Paragraph>
    </Section>
    <Section theme="purple">
      <H1>Getting Started</H1>
      <Paragraph>
        {({ Hl, Break, Nl }) => (
          <>
            Fill out the info below to get a free consultation on how Boostly
            would work in your restaurant:
          </>
        )}
      </Paragraph>
      <Form />
    </Section>
  </Box>
)

const postToSlack = ({ text, channel }) =>
  console.log(text, channel) ||
  axios.post(
    `https://hooks.slack.com/services/T73B5HUKC/${channel}`,
    JSON.stringify({ text }),
    {
      withCredentials: false,
      transformRequest: [
        (data, headers) => {
          delete headers.post["Content-Type"]
          return data
        },
      ],
    }
  )

const Form = () => {
  const [name, setName] = React.useState("")
  const [rest, setRest] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [number, setNumber] = React.useState("")
  const [submitted, setSubmitted] = React.useState(false)

  const onSubmit = e => {
    e.preventDefault()
    postToSlack({
      channel: "BGUA8L73P/3chQkyG23djofSW5bzhZvA4F",
      text: `New Lead 🚨
name: ${name}
restaurant: ${rest}
email: ${email}
number: ${number}`,
    }).catch(e => console.log(e))

    setSubmitted(true)
  }

  if (submitted) {
    return (
      <Box color="white" textAlign="center" py={4}>
        <Text.title>Thanks!</Text.title>
        <p>We'll be in contact shortly!</p>
      </Box>
    )
  }

  return (
    <Box is="form" onSubmit={onSubmit}>
      <InputField
        label="Name"
        value={name}
        onChange={({ target }) => setName(target.value)}
      />
      <InputField
        label="Restaurant"
        value={rest}
        onChange={({ target }) => setRest(target.value)}
      />
      <InputField
        label="Email"
        value={email}
        onChange={({ target }) => setEmail(target.value)}
      />
      <InputField
        label="Number"
        mask="(999) 999 9999"
        maskChar=" "
        value={number}
        onChange={({ target }) => setNumber(target.value)}
      />
      <Box pt={3}>
        <Button.second type="submit">Submit</Button.second>
      </Box>
    </Box>
  )
}

export default IndexPage

const themes = {
  blue: {
    startHex: settings.colors.blue,
    endHex: settings.colors.purple,
  },
  red: {
    startHex: settings.colors.red,
    endHex: settings.colors.purple,
  },
  purple: {
    startHex: settings.colors.purple,
    endHex: settings.colors.purple,
  },
}

const Input = styled(InputMask)`
  width: 100%;
  height: 40px;
  border-radius: 5px;
  transition: 0.25s;
  background-color: white;
  padding-left: 1em;
`
const Label = ({ label, children }) => (
  <Row pr={2} pb={1}>
    <Text.title color={"white"}>{label || children}</Text.title>
  </Row>
)
const InputField = ({ label, width = "100%", ...rest }) => (
  <Col py={1} w={width}>
    <Label label={label} />
    <Box>
      <Input {...rest} />
    </Box>
  </Col>
)

const Showcase = ({ children }) => (
  <Box>
    <Text.title size={2}>{children[0]}</Text.title>
    <Box
      pt={2}
      pb={2}
      is="p"
      css={css`
        text-indent: 1.5em;
        font-family: monospace;
      `}
    >
      {children[1]}
    </Box>
  </Box>
)

const Section = ({ children, theme = "blue", ...rest }) => (
  <Gradient
    {...themes[theme]}
    degree="-90"
    {...rest}
    py={4}
    position="relative"
    css={css`
      overflow: hidden;
    `}
  >
    <Box m="0 auto" maxWidth="400px">
      {children}
    </Box>
  </Gradient>
)

const Heading = props => (
  <Box bg="white" py={3} my={2} pl={3}>
    <Text.title color={props.color} size={"22px"}>
      {props.children}
    </Text.title>
  </Box>
)

const Paragraph = props => (
  <Box px={3} py={3} m="0 auto">
    <Text.title color="white" lineHeight={1.3} size={"18px"}>
      {props.children({
        Hl: ({ children }) => (
          <Box is="span" color={props.color} bg="white">
            {children}
          </Box>
        ),
        Break: () => <Box py={3} />,
        Nl: () => <Box />,
      })}
    </Text.title>
  </Box>
)

const Logo = ({ size = "20px" }) => (
  <Box
    is="img"
    m="0"
    height={size}
    src={require("../images/wordmark-white.svg")}
    alt="Boostly Word Logo"
  />
)

const Logomark = ({ size = "20px" }) => (
  <Box
    is="img"
    m="0"
    height={size}
    src={require("../images/logomark-black.svg")}
    alt="Boostly Logo"
  />
)
