import { css } from "@emotion/core"
import { utils } from "boostly-ui2"
export const makeGradientCss = ({ degree = 0, startHex, endHex }) => css`
  background: ${utils.opacity(endHex, 1)};
  background: linear-gradient(
    ${degree}deg,
    ${utils.opacity(endHex, 1)} -70%,
    ${utils.opacity(startHex, 1)} 100%
  );
`
